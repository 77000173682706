// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,

  // Configuration of content
  appName: 'Helpful Information',
  mainPageHeader: 'Сайт корисної інформації від "Доркас"',
  mainPageIntroduction: 'Веберіть район:',

  // Regions:
  regions: 'mukachevo,uzhhorod',
  regionsLabels: 'Мукачево,Ужгород',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyC2NOcBTJrGV7iNa8eH_dq7Ya9OiIIGNXA',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',
  google_sheets_sheet_ids: '1XK6eQTTVfm6R4U1NSt8UVIHhIhqSoQ5tgwCg9OyQKZw,19nhQAMFQdNvp2jJQpJlzU3LKXy2lRPNiNCpStO1iOdI',

  // Application Insights
  appInsightsConnectionString: 'InstrumentationKey=be3a1a26-dc56-4719-ae7d-be5a2835bbf0;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/',
};
